<template>


<v-layout class="pa-0">

  <v-flex justify-center>


<div v-resize="onResize">

  <v-data-table
    :headers="headers"
    :items="ArrayClientes"
    :search="search"
    :height="tableHeight"
    sort-by="calories"
    class="elevation-1 mx-2 mt-3 mb-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Salud Flota Clientes</v-toolbar-title>
        <v-divider  class="mx-4" inset vertical></v-divider>


        <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>


          <v-switch class="ml-5 mt-5"
              v-model="switch1"
              flat
              :label="LabelSwitch"
            ></v-switch>


            
          <v-icon  color="gray" class="mx-5" @click="verInfo()">info</v-icon>


        <v-spacer></v-spacer>


          <v-btn color="success" @click="listar();">
              <v-icon>restart_alt</v-icon>
              </v-btn>
        
        <v-dialog v-model="dialog" max-width="700px">

          <v-card>
           
          <v-toolbar color="red">
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>
                  
                  <v-col  cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="editedItem.nombre_cliente"
                      prepend-icon="person"
                      label="Nombre cliente AVL"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.usr_avl"
                      label="Usuario AVL"
                      prepend-icon="vpn_key"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.pwd_avl"
                      prepend-icon="lock"
                      label="Pwd AVL"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12"  sm="6" md="4">
                    <v-select
                    prepend-icon="flag"
                              v-model="editedItem.pais_avl"
                              :items="PaisesArray"
                              label="Pais Avl"
                          ></v-select>
                  </v-col>


                  <v-col  cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.servidor_avl"
                      prepend-icon="storage"
                      label="Servidor AVL"
                    ></v-text-field>
                  </v-col>


                   <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.responsable"
                      prepend-icon="face"
                      label="nombre responsable"
                    ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.telefono"
                      prepend-icon="phone"
                      label="Telefono"
                    ></v-text-field>
                  </v-col>



                   <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.correo"
                      prepend-icon="email"
                      label="Correo"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12"  sm="6" md="6">
                    <v-select
                    prepend-icon="switch_account"
                              v-model="editedItem.id_ejecutivo_cli"
                              :items="ArrayEjecutivos"
                              label="Ejecutivo"
                          ></v-select>
                  </v-col>




                  <v-col cols="12" sm="5" md="5" class="d-inline-flex mt-3">
                      <span class="d-inline-flex mx-2"><strong>Salud Flota:</strong></span>
                    <input type="radio" value="1" v-model="editedItem.activo_salud" class="d-inline mx-1">Activo
                    <input type="radio" value="0" v-model="editedItem.activo_salud" class="d-inline mx-1">Inactivo
 
                  </v-col>




                   <v-col  cols="12" xs="12" sm="12" md="12" lg="12">


                     <div class="d-flex align-start">

                        <v-checkbox   v-for="item in diasArray" v-bind:key="item.value" 
                        v-model="checkedDias" :label="item.text" :value="item.value" class="mx-1"
                        ></v-checkbox>
                      

                     </div>

                     </v-col>


                </v-row>
                 <span v-if="checkedDias.length < 1"> no hay dias asociados a cliente</span>
                          

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              
              <v-btn  color="blue darken-1"  text @click="close">
                Cancelar
              </v-btn>

              <v-btn color="green" @click="save" dark>Guardar
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas deshabilitar envio salud a cliente?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red white--text"  @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)"> delete</v-icon>
    </template>


      <template v-slot:[`item.dias_activo`]="{ item }">
      <v-chip color="indigo" small   text-color="white" v-for="(ite, index) in item.dias_activo" v-bind:key="index">
         {{  renderizadDias(ite) }}
      </v-chip>
    </template>




    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">
        Reset
      </v-btn>
    </template>

  </v-data-table>



</div>

  </v-flex>
</v-layout>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";

  export default {
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
       PaisesArray:[{text:'NI', value:'ni'},{text:'SV', value:'sv'},{text:'GT', value:'gt'},{text:'PA', value:'pa'}],
      headers: [
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: 'Nombre Cliente', value: 'nombre_cliente' },
        { text: 'Usr avl', value: 'usr_avl' },
        { text: 'Pwd avl', value: 'pwd_avl' },
        { text: 'Responsable', value: 'responsable' },  
        { text:'Dias activo', value:'dias_activo'},
        { text:'Ejecutivo', value:'id_ejecutivo_cli.nombre_ejecutivo'},
        { text:'#Vehiculos', value:'cantidad_vehiculos'},
        { text:'Correo', value:'correo'},
      
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        nombre_cliente: '',
        usr_avl: '',
        pwd_avl:'',
        pais_avl: '',
        servidor_avl: '',
        responsable:'',
        telefono:'',
        correo:'',
        numero_whatsapp:'',
        dias_activo:'',
        id_ejecutivo_cli:'',
        activo:1,
        activo_salud:1,
        creado:new Date()
      },
      defaultItem: {
        nombre_cliente: '',
        usr_avl: '',
        pwd_avl:'',
        pais_avl: '',
        servidor_avl: '',
        responsable:'',
        telefono:'',
        correo:'',
        dias_activo:'',
        id_ejecutivo_cli:'',
        activo:1,
        activo_salud:1,
        creado:new Date()
      },

      diasArray:[{text:'lunes', value:1},{text:'martes', value:2},{text:'miercoles', value:3},
      {text:'jueves', value:4},{text:'viernes', value:5},{text:'sabado', value:6}],
      checkedDias:[],

      ArrayEjecutivos:[],
      ArrayClientes:[],
      arreglo:[],

       tableHeight: 0,

       switch1:true,
       LabelSwitch:'Activos'
      
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo cliente' : 'Edicion cliente'
      },

        ...mapState(['usuario', 'token']),

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      switch1(val){
          if(val==true){
             this.LabelSwitch='Activos';
             this.ActulizarTabla();
          }else{
               this.LabelSwitch='Inactivos';
                this.ActulizarTabla();
          }
      }


    },

    created () {
    
      this.listar();
      this.listarEjecutivos();
    },

    methods: {

      ...mapMutations(['mostrarLoading','ocultarLoading']),
    
      listar(){

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

           me.arreglo=[];
          axios.get('clientes-activos',
         config
          ).then(function(response){
             console.log(response);
             me.ArrayClientes=[];
            me.arreglo=response.data;

            me.arreglo.map(function(x){

              //aqui solo ponemos los datos si es admin o si son del usuario ejecutvo actual
             
               if((me.usuario.rol=='ADMIN')||(x.id_ejecutivo_cli._id==me.usuario._id)){

                 if((me.switch1==true)&&(x.activo_salud==1)){
                      me.ArrayClientes.push(x);
                 }
                   
               }
                 
            });



           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },


      listarEjecutivos(){
        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'Accediendo a datos'});
          let arregloe=[];
         let me=this;
          axios.get('ejecutivos',
         config
          ).then(function(response){
             console.log(response);
             arregloe=response.data;

             arregloe.map(function(x){
               me.ArrayEjecutivos.push({text:x.nombre_ejecutivo, value:x._id});
             });

           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });
      },

      editItem (item) {
        this.editedIndex = this.ArrayClientes.indexOf(item)
        this.editedItem = Object.assign({}, item);

        this.checkedDias=[];
        this.checkedDias=this.editedItem.dias_activo;
        
       this.editedItem.id_ejecutivo_cli=item.id_ejecutivo_cli._id;

        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayClientes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true;
      },

      deleteItemConfirm () {


           let config={headers:{token:this.token}};
            
                    axios.put(`clientes/${this.editedItem._id}`,
                              { '_id':this.editedItem._id,'activo_salud':0},
                            config
                        ).then(response=>{
                      
                        console.log(response);
                     

                        this.closeDelete();
                        this.listar();

                  }).catch(e=>{
                        console.log(e)
                  }); 
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {


          //Object.assign(this.desserts[this.editedIndex], this.editedItem)



           //actualizar datos de item
                    let config={headers:{token:this.token}};
                    let me=this;
             
                    let id=this.editedItem._id;
                     this.mostrarLoading({titulo:'Accediendo a datos'});

                    axios.put(`clientes/${id}`,
                      { 
                           'nombre_cliente':this.editedItem.nombre_cliente,
                            'usr_avl':this.editedItem.usr_avl,
                            'pwd_avl':this.editedItem.pwd_avl,
                            'pais_avl':this.editedItem.pais_avl,
                            'servidor_avl':this.editedItem.servidor_avl,
                            'telefono':this.editedItem.telefono,
                            'responsable':this.editedItem.responsable,
                            'correo':this.editedItem.correo,
                            'dias_activo':this.checkedDias,
                            'id_ejecutivo_cli':this.editedItem.id_ejecutivo_cli,
                            'cantidad_vehiculos':0,
                            'activo':this.editedItem.activo
                       },
                      config

                        ).then(response=>{
                      
                        console.log(response);
                         me.ocultarLoading();
                     
                        this.listar();
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });


         


        } else {
          // this.desserts.push(this.editedItem)
          //guardamos datos

           //nuevo cliente

                let me=this;


          if((this.editedItem.nombre_cliente!='')&&(this.editedItem.usr_avl!='')&&
          (this.editedItem.pwd_avl!='')&&(this.editedItem.pais_avl!='')&&(this.editedItem.servidor_avl!='')
                           &&(this.editedItem.responsable!='')){ 


                  let unico=true;

                  me.ArrayClientes.map(function(x){
                        if((x.nombre_cliente===me.editedItem.nombre_cliente)||(x.usr_avl===me.editedItem.usr_avl)){
                              unico=false;
                        }
                  });

                           


               if(unico==true){


                            let config={headers:{token:this.token}};
                            this.mostrarLoading({titulo:'Accediendo a datos'});

                              axios.post('nuevo-cliente',
                                        { 
                                          'nombre_cliente':this.editedItem.nombre_cliente,
                                          'usr_avl':this.editedItem.usr_avl,
                                          'pwd_avl':this.editedItem.pwd_avl,
                                          'pais_avl':this.editedItem.pais_avl,
                                          'servidor_avl':this.editedItem.servidor_avl,
                                          'telefono':this.editedItem.telefono,
                                          'responsable':this.editedItem.responsable,
                                          'correo':this.editedItem.correo,
                                          'dias_activo':this.checkedDias,
                                          'id_ejecutivo_cli':this.editedItem.id_ejecutivo_cli,
                                          'cantidad_vehiculos':0,
                                          'activo':this.editedItem.activo
                                          },
                                    config
                              ).then(response=>{
                              
                                this.close();
                                me.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                                  
                              });


                 }else{
                 alert('cliente repetido');
               }




        }else{
          alert('faltan campos');
        }








        }
        
      },

      renderizadDias(valor){
        
        let texto='';
        this.diasArray.map(function(x){
            if(x.value==valor){
              texto=x.text;
            }
        });

        return(texto);

      },


        onResize() {

                if (window.innerWidth < 769){

                  //mobile
                  this.tableHeight = window.innerHeight - 20;
                }else{
                  //pantalla grande
                  this.tableHeight = window.innerHeight - 245;
                }
       },


       ActulizarTabla(){

         console.log('estado de swithc:'+this.switch1);

         let me=this;
          me.ArrayClientes=[];


             me.arreglo.map(function(x){

              //aqui solo ponemos los datos si es admin o si son del usuario ejecutvo actual
             
               if((me.usuario.rol=='ADMIN')||(x.id_ejecutivo_cli._id==me.usuario._id)){

                 if((me.switch1==true)&&(x.activo_salud==1)){
                      me.ArrayClientes.push(x);
                 }

                  if((me.switch1==false)&&(x.activo_salud==0)){
                      me.ArrayClientes.push(x);
                      console.log('aqio bien');
                 }

            



                   
               }

                    console.log('varlo activo:'+x.activo);
                 
            });


       },

       verInfo(){
         alert("Observaciones:#vehiculos en cero podria indicar usuario y  contraseña incorrectas, \n correos deben seprarse por punto y comas, \n servidores pueden establecerse por pais: ni, sv, pa, gt o bien empresa.");
       }


    },
  }
</script>