<template>


<v-layout class="pa-0">

  <v-flex justify-center>


<div v-resize="onResize">

  <v-data-table
    :headers="headers"
    :items="ArrayClientes"
    :search="search"
    :height="tableHeight"
    sort-by="calories"
    class="elevation-1 mx-2 mt-3 mb-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Clientes Avl</v-toolbar-title>
        <v-divider  class="mx-4" inset vertical></v-divider>


        <v-flex  xs6 sm6 md6 lg6>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>


          <v-switch class="ml-5 mt-5"
              v-model="switch1"
              flat
              :label="LabelSwitch"
            ></v-switch>


            
        <v-icon  color="gray" class="mx-5" @click="verInfo()">info</v-icon>


             
        <v-dialog v-model="dialog" max-width="700px" persistent>

          <template v-slot:activator="{ on, attrs }">


          

            <v-btn color="primary"  dark class="mx-1" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>

              <v-btn color="success" @click="listar();">
              <v-icon>restart_alt</v-icon>
              </v-btn>

          </template>


          <v-card>
           
          <v-toolbar color="red">
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>
                  
                  <v-col  cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="editedItem.nombre_cliente"
                      prepend-icon="person"
                      label="Nombre cliente AVL"
                    ></v-text-field>
                  </v-col>

                <v-col cols="12" sm="6" md="6" class="d-inline-flex mt-3">
                     <span class="d-inline-flex mx-2"><strong>Estatus cliente:</strong></span>
                    <input type="radio" value="1" v-model="editedItem.activo" class="d-inline mx-1"> Activo
                    <input type="radio" value="0" v-model="editedItem.activo" class="d-inline mx-1"> Inactivo
 
                  </v-col>

                 



                  <v-col cols="12" sm="6" md="6" v-show="editedItem.activo_salud=='1'">
                    <v-text-field
                      v-model="editedItem.usr_avl"
                      label="Usuario AVL"
                      prepend-icon="vpn_key"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="4" v-show="editedItem.activo_salud=='1'">
                    <v-text-field
                      v-model="editedItem.pwd_avl"
                      prepend-icon="lock"
                      label="Pwd AVL"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12"  sm="5" md="3">
                    <v-select
                    prepend-icon="flag"
                              v-model="editedItem.pais_avl"
                              :items="PaisesArray"
                              label="Pais Avl"
                          ></v-select>
                  </v-col>


                  <v-col  cols="12" sm="5" md="3">
                    <v-text-field
                      v-model="editedItem.servidor_avl"
                      prepend-icon="storage"
                      label="Servidor AVL"
                    ></v-text-field>

                  </v-col>

                
               <v-col cols="12" sm="5" md="5" class="d-inline-flex mt-3">
                      <span class="d-inline-flex mx-2"><strong>Salud Flota:</strong></span>
                    <input type="radio" value="1" v-model="editedItem.activo_salud" class="d-inline mx-1">Activo
                    <input type="radio" value="0" v-model="editedItem.activo_salud" class="d-inline mx-1">Inactivo
 
                  </v-col>



                   <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.responsable"
                      prepend-icon="face"
                      label="nombre responsable"
                    ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.telefono"
                      prepend-icon="phone"
                      label="Telefono"
                    ></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.numero_whatsapp"
                      prepend-icon="whatsapp"
                      label="Numero whatsapp"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12"  sm="6" md="6">
                    <v-select
                    prepend-icon="switch_account"
                              v-model="editedItem.id_ejecutivo_cli"
                              :items="ArrayEjecutivos"
                              label="Ejecutivo"
                          ></v-select>
                  </v-col>



                   <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.correo"
                      prepend-icon="email"
                      label="Correo"
                    ></v-text-field>
                  </v-col>



                   <v-col  cols="12" xs="12" sm="12" md="12" lg="12" v-show="editedItem.activo_salud=='1'">


                     <div class="d-flex align-start">

                        <v-checkbox   v-for="item in diasArray" v-bind:key="item.value" 
                        v-model="checkedDias" :label="item.text" :value="item.value" class="mx-1"
                        ></v-checkbox>
                      

                     </div>

                     </v-col>


                </v-row>
                 <span v-if="checkedDias.length < 1 && editedItem.activo_salud=='1'"> no hay dias asociados a cliente</span>
                          

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              
              <v-btn  color="blue darken-1"  text @click="close">
                Cancelar
              </v-btn>

              <v-btn color="green" @click="save" dark>Guardar
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-spacer></v-spacer>
        
    

          <v-dialog v-model="dialogContactos" max-width="780px" persistent>
            <v-card>
            <v-toolbar color="red">
            <v-toolbar-title class="white--text"> Contactos de: {{ nombre_cliente_texto }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialogContactos=false">close</v-icon>
          </v-toolbar>

          <v-card-text>
          
            <v-data-table
            :headers="headersContact"
            :items="itemsContact"
            sort-by="calories"
            class="elevation-1 mt-5"
          >

          </v-data-table>

           

          </v-card-text>

            </v-card>
          </v-dialog>



        

         <v-dialog v-model="dialogNoTx" fullscreen hide-overlay>
            <v-card>
        <v-toolbar color="red">
        <v-toolbar-title class="white--text inline-flex"><span class="mt-1"> {{ ItemTX.nombre_cliente }}  - {{  ItemTX.unidades_notx }} unidades</span>
         

        </v-toolbar-title>

       
          <v-text-field  v-model="searchtx" solo-inverted dense single-line hide-details  dark prepend-inner-icon="search" 
          
           label="Search" class="hidden-sm-and-down"></v-text-field>
        


         <v-flex class="ml-12 mt-1">
           <v-btn color="opcion1" small @click="SyncronizarAVLVeh()"><v-icon>sync</v-icon>Sync AVL</v-btn>
         </v-flex>

        <v-spacer></v-spacer>
        <v-icon dark @click="dialogNoTx=false">close</v-icon>
        </v-toolbar>

           <v-card-text>



                    
                           <v-data-table class="mt-1"
                              :headers="headersSelected"
                              :search="searchtx"
                              :items="itemSelected"
                            >
       
                                    <template v-slot:[`item.fecha_gps`]="{ item }">
                                      <span>{{ getDate(item.fecha_gps) }}</span>
                                        <v-icon  v-if="ValidariconoSiTX(item.fecha_gps)==true" color="success">event_available</v-icon>
                                    </template>


                                      <template v-slot:[`item.estado_check`]="{ item }">
                                        <v-checkbox
                                          v-model="item.estado_check"
                                        ></v-checkbox>    
                                      </template>

                                     <template v-slot:[`item.ubicacion`]="{ item }">
                                        <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                      </template>

                                       <template v-slot:[`item.vence_estado`]="{ item }">
                                            <span v-if="item.vence_estado!=null">{{ new Date(item.vence_estado).toISOString().substr(0, 10) }}</span>
                                               <v-icon  v-if="item.vence_estado!=null" color="red">{{ evaluarVencimineto(item.vence_estado)}}</v-icon>
                                     
                                      </template>


                                      <template v-slot:[`item.fecha_gestion`]="{ item }">
                                            <span v-if="item.fecha_gestion!=null">{{ getDate(item.fecha_gestion) }}</span>
                                      </template>

                                       <template v-slot:[`item.id_estado`]="{ item }">

                                      <v-chip v-if="item.id_estado.nombre_estado=='Desconocido'"  small color="red" outlined>
                                        <span>{{ item.id_estado.nombre_estado }}</span>
                                      </v-chip>


                                      <v-chip v-else-if="item.id_estado.nombre_estado=='Sin confirmar'" small color="red" outlined>
                                        <span>{{ item.id_estado.nombre_estado }}</span>
                                      </v-chip>


                                      <v-chip v-else small color="blue" outlined >
                                        <span>{{ item.id_estado.nombre_estado }}</span>
                                      </v-chip>



                                         


                              </template>

                              
                            </v-data-table>

                             <v-flex xs12 sm12 md12 lg12>
                                <span class="d-block">
                                <v-icon color="red">error</v-icon>
                                Estados vencidos de vehiculos</span>
                                </v-flex>


                      


                          </v-card-text>

            </v-card>
         </v-dialog>



          <v-dialog v-model="dialogGestiones" max-width="780px" persistent>
            <v-card>
            <v-toolbar color="red">
            <v-toolbar-title class="white--text"> Gestiones de: {{ ItemGestion.nombre_cliente }} </v-toolbar-title>

            <v-btn color="opcion1" class="ml-8" @click="dialogGestionfecha=true"><v-icon>today</v-icon></v-btn>

            <v-spacer></v-spacer>
            <v-icon dark @click="dialogGestiones=false">close</v-icon>
          </v-toolbar>

          <v-card-text>
            <!--v-row class="mt-3">
              <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="NuevaDireccion()"><v-icon>add</v-icon></v-btn>
            </v-row-->

            <v-data-table
            :headers="headersGestiones"
            :items="ArrayGestionesCliente"
            sort-by="calories"
            class="elevation-1 mt-5"
          >

        
          </v-data-table>

           

          </v-card-text>

            </v-card>
          </v-dialog>


          
 <v-dialog v-model="dialogGestionfecha" persistent max-width="280">
        <v-card>
          <v-toolbar flat color="red" class="text-center">
          <v-toolbar-title class="white--text  text-center"><strong>Gestiones por fechas</strong></v-toolbar-title>
        </v-toolbar>

          <v-card-text>



            <v-flex>
        
            

                      <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_inicio"
                                label="Fecha inicio"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
                </v-flex>

            <v-flex>
                  <v-menu
                    ref="menuFecha2"
                    v-model="menuFecha2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                
                    offset-y
                    min-width="290px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_fin"
                            label="Fecha fin"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fecha_fin" locale="es"
                        no-title  @input="menuFecha2 = false" scrollable>
                        </v-date-picker>
                  </v-menu>

                </v-flex>

            
                  <v-flex justify-center d-flex>
                    
                        <v-btn color="success" dark @click="VerGestionesFecha()" style="margin-right:10px;">Generar</v-btn>
                      <v-btn  @click="dialogGestionfecha=false" style="margin-right:10px;">Cancelar</v-btn>

                </v-flex>
    
          </v-card-text>
        
        </v-card>
    </v-dialog>
        

  <v-dialog v-model="dialogDirecciones" max-width="780px" persistent>
            <v-card>
            <v-toolbar color="red">
            <v-toolbar-title class="white--text"> Direcciones de: {{ nombre_cliente_texto }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialogDirecciones=false">close</v-icon>
          </v-toolbar>

          <v-card-text>
           

            <v-data-table
            :headers="headersDirecciones"
            :items="itemsDirecciones"
            sort-by="calories"
            class="elevation-1 mt-5"
          >

           <template v-slot:[`item.mapa`]="{ item }">
                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
          </template>

           <template v-slot:[`item.actions`]="{ item }">
             <v-icon small  @click="deleteDireccion(item)">delete</v-icon>
           </template>
          
          
          </v-data-table>

           

          </v-card-text>

            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDeleteDireccion" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Seguro deseas deshabilitar este registro?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="dialogDeleteDireccion=false">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirmDireccion">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>


    <v-dialog v-model="dialogBitacoras" persistent max-width="1090px">

         <v-card>
            <v-toolbar color="red">
            <v-toolbar-title class="white--text">Bitacoras de: {{ ItemBitacora.nombre_cliente  }} </v-toolbar-title>
           
            <v-btn color="opcion1" class="ml-8" @click="dialogBitacorafecha=true"><v-icon>today</v-icon></v-btn>

            <v-spacer></v-spacer>
            <v-icon dark @click="dialogBitacoras=false">close</v-icon>
          </v-toolbar>

          <v-card-text>

             <v-text-field
        v-model="searchBitacora"
        append-icon="search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>

                <v-data-table
                :headers="headersBitacoraCliente"
                :items="ArrayBitacoraCliente"
                :search="searchBitacora"
                sort-by="calories"
                class="elevation-1 mt-5"
              >

               <template v-slot:[`item.fecha`]="{ item }">
                <span>{{ getDate(item.fecha) }}</span>
               </template>


                <template v-slot:[`item.id_tipo_trabajo`]="{ item }"> 
                <span>{{item.id_tipo_trabajo.nombre_trabajo}}</span>
                  <v-icon color="indigo" @click="VerDetalleBitacora(item)">preview</v-icon>
                </template>

                <template v-slot:[`item.observacion_operador`]="{ item }">           
                    <span class="text-caption">{{ item.observacion_operador }}</span>
                </template>

              </v-data-table>

          </v-card-text>

            </v-card>


      </v-dialog>



  <v-dialog v-model="dialogDetalleBitacora" persistent width="800">

      

      <v-card>

         <v-toolbar   color="red">
         <v-toolbar-title class="white--text">Detalle Bitacora</v-toolbar-title>

         <v-spacer></v-spacer>
         <v-icon @click="dialogDetalleBitacora=false" dark>close</v-icon>

        </v-toolbar>


          
            <v-card-text class="mt-3">

               <v-carousel :continuous="false" :show-arrows="true" class="pb-0"
              hide-delimiter-background delimiter-icon="crop_7_5" height="500">

               <!-- primer carrusel-->
            
            <v-carousel-item>


              <v-row>


                      <v-col cols="5">
                            <v-list>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">today</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ ConvertirFecha(viewItem.fecha) }}</v-list-item-title>
                                    <v-list-item-subtitle>Fecha hora</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>


                  


                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">contact_page</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.contacto }}</v-list-item-title>
                                    <v-list-item-subtitle>contacto</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>


                                  <v-list-item class="pt-0" v-if="viewItem.id_tipo_trabajo">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">home_repair_service</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.id_tipo_trabajo.nombre_trabajo }}</v-list-item-title>
                                    <v-list-item-subtitle>Tipo trabajo</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">label</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.status }}</v-list-item-title>
                                    <v-list-item-subtitle>Estatus</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">comment</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                        <span class="text--caption">{{ viewItem.observacion_operador }}</span>
                                    <v-list-item-subtitle>Observacion Operador</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                    <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">local_offer</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.etiqueta }}</v-list-item-title>
                                    <v-list-item-subtitle>Etiqueta</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                   <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">shutter_speed</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title> <strong>{{ viewItem.hora_llegada_tecnico }} </strong>  </v-list-item-title>
                                    <v-list-item-subtitle>llegada Tecnico</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>



                            </v-list>
                        </v-col>

                          <v-col cols="6">
                            <v-list>

                                
                                  <v-list-item class="pt-0" v-if="viewItem.id_bitacora_cliente">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">account_circle</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.id_bitacora_cliente.nombre_cliente }}</v-list-item-title>
                                    <v-list-item-subtitle>Nombre cliente</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                
                                  <v-divider inset></v-divider>


                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">apartment</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <span class="text--caption">{{ viewItem.direccion }}</span>
                                    <v-list-item-subtitle>Direccion</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">description</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                        <span class="text--caption">{{ viewItem.descripcion_trabajo }}</span>
                                    <v-list-item-subtitle>Descripcion Trabajo</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>




                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0" >
                                    <v-list-item-icon>
                                        <v-icon color="indigo">timer</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title> inicio: <strong>{{ viewItem.hora_inicio }} </strong>  fin:  <strong> {{ viewItem.hora_fin }} </strong></v-list-item-title>
                                    <v-list-item-subtitle>Horas realizado</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>



                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0" >
                                    <v-list-item-icon>
                                        <v-icon color="indigo">manage_accounts</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>
                                      
                                      <div v-for="(item, index) in viewItem.id_tecnicos"  :key="index">
                                        <span> {{ renderizarTecnico(item.ref_tecnico) }}  /</span>
                                      </div>
                                      
                                      
                                      </v-list-item-title>
                                    <v-list-item-subtitle>Tecnico</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0" >
                                    <v-list-item-icon>
                                        <v-icon color="indigo">edit</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                      <span class="text--caption">{{ viewItem.editado }}</span>
                                    <v-list-item-subtitle>Editado</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                     <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">arrow_circle_down</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                   
                                        <v-btn v-if="viewItem.pdf_diagrama"  x-small @click="DescargarPdf(viewItem.pdf_diagrama)" >Diagrama</v-btn>
                                        <v-btn v-if="viewItem.pdf_diagrama_orden" class="mt-2" x-small @click="DescargarPdf(viewItem.pdf_diagrama_orden)">Hoja trabajo</v-btn>
                      
                                   
                                  </v-list-item-content>
                                  </v-list-item>




                            </v-list>
                        </v-col>

              </v-row>

             </v-carousel-item>


            <!-- segundo carrusel-->


               <v-carousel-item>  
                  <v-row>

                      <v-col cols="4" class="pl-2">
                            <v-list>

                                  <v-list-item class="pt-0" v-if="viewItem.id_tipo_equipo">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">beenhere</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.id_tipo_equipo.nombre_equipo }}</v-list-item-title>
                                    <v-list-item-subtitle>Tipo equipo</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>



                                   <v-divider inset></v-divider>


                                   <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">closed_caption</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.placa }}</v-list-item-title>
                                    <v-list-item-subtitle>Placa</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>



                                  <v-divider inset v-show="viewItem.imei_anterior!=''"></v-divider>

                                   <v-list-item class="pt-0" v-show="viewItem.imei_anterior!=''">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">undo</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.imei_anterior }}</v-list-item-title>
                                    <v-list-item-subtitle>Imei anterior</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>




                  
                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">branding_watermark</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.marca }}</v-list-item-title>
                                    <v-list-item-subtitle>Marca</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                
                                  <v-divider inset></v-divider>


                                    <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">today</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.anio }}</v-list-item-title>
                                    <v-list-item-subtitle>Año</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                  
                                

                                 


                                 


                                    <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">book_online</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.imei }}</v-list-item-title>
                                    <v-list-item-subtitle>Imei</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>



                                    <v-divider inset></v-divider>

                                     <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">support_agent</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.proveedor_simcard }}</v-list-item-title>
                                    <v-list-item-subtitle>Proveedor</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>
                                  

                                    <v-divider inset></v-divider>

                                  



                            </v-list>
                      </v-col>


                      <v-col cols="4">

                         <v-list>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">tag</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.id_avl }}</v-list-item-title>
                                    <v-list-item-subtitle>Id Avl</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                <v-divider inset v-show="viewItem.placa_anterior!=''"></v-divider>

                                  <v-list-item class="pt-0" v-show="viewItem.placa_anterior!=''">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">backspace</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.placa_anterior }}</v-list-item-title>
                                    <v-list-item-subtitle>Placa anterior</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>




                                <v-divider inset></v-divider>


                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">commute</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.flota }}</v-list-item-title>
                                    <v-list-item-subtitle>Flota</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                 
                                    <!-- aqui viene-->
                                

                                  <v-divider inset></v-divider>


                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">subtitles</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.modelo }}</v-list-item-title>
                                    <v-list-item-subtitle>Modelo</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>




                                    <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">confirmation_number</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                        <span class="text--caption">{{ viewItem.chasis }}</span>
                                    <v-list-item-subtitle>Chasis</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                 

                                 

                                  <v-divider inset></v-divider>

                                  

                                     <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">sim_card</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.simcard }}</v-list-item-title>
                                    <v-list-item-subtitle>Simcard</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">notes</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                      <span class="text--caption">{{ viewItem.ubicacion_equipo }}</span>
                                    <v-list-item-subtitle>Ubicacion equipo</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>

                                  
                                   


                                  


                                  


                         </v-list>       



                      </v-col>

                        <v-col cols="4">

                                 <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">label_important</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.nombre }}</v-list-item-title>
                                    <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>




                                   <v-divider inset v-show="viewItem.serie_anterior!=''"></v-divider>
                                  
                                   <v-list-item class="pt-0" v-show="viewItem.serie_anterior!=''">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">fast_rewind</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.serie_anterior }}</v-list-item-title>
                                    <v-list-item-subtitle>Serie anterior</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>




                                  <v-divider inset></v-divider>

                                   <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">directions_car_filled</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.estilo }}</v-list-item-title>
                                    <v-list-item-subtitle>Estilo</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                   
                                <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">palette</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                       <v-list-item-title>{{ viewItem.color }}</v-list-item-title>
                                    <v-list-item-subtitle>Color</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                   <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">format_list_numbered</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.serie }}</v-list-item-title>
                                    <v-list-item-subtitle>Serie</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                    <v-divider inset></v-divider>

                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">sim_card</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ viewItem.simcard_anterior }}</v-list-item-title>
                                    <v-list-item-subtitle>simcard Ant.</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>


                                   <v-divider inset></v-divider>

                                     <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">comment</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                       <span class="text--caption">{{ viewItem.observacion_tecnico }}</span>
                                    <v-list-item-subtitle>Observacion Tecnico</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>

                                  <v-divider inset></v-divider>

                                 


                                  

                                    


                                 


                                 

                        </v-col>
                   
                </v-row>
              </v-carousel-item>



              <!-- tercer carrusel --->
               <v-carousel-item>

                  <v-row>

                      <v-col cols="4" class="pl-2">
                            <v-list>


                                  <v-list-item class="pt-0">
                                    <v-list-item-icon>
                                        <v-icon color="indigo">headphones_battery</v-icon>
                                      </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>
                                   

                                       <div v-for="(item, index) in viewItem.id_accesorios_instalados"  :key="index">
                                        <span class="text--caption"> {{ renderizarAccesorio(item.ref_accesorio) }}  /</span>
                                      </div>
                                      
                                      </v-list-item-title>
                                    <v-list-item-subtitle>Accesorios instalados</v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>



                               
                            </v-list>
                      </v-col>
                  </v-row>


               </v-carousel-item>


         </v-carousel>


            </v-card-text>
           
          </v-card>
    </v-dialog>






      <v-dialog v-model="dialogBitacorafecha" persistent max-width="280">
        <v-card>
          <v-toolbar flat color="red" class="text-center">
          <v-toolbar-title class="white--text  text-center"><strong>Bitacora por fechas</strong></v-toolbar-title>
        </v-toolbar>

          <v-card-text>



            <v-flex>
        
            

                      <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_inicio"
                                label="Fecha inicio"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
                </v-flex>

            <v-flex>
                  <v-menu
                    ref="menuFecha2"
                    v-model="menuFecha2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                
                    offset-y
                    min-width="290px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_fin"
                            label="Fecha fin"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fecha_fin" locale="es"
                        no-title  @input="menuFecha2 = false" scrollable>
                        </v-date-picker>
                  </v-menu>

                </v-flex>

            
                  <v-flex justify-center d-flex>
                    
                        <v-btn color="success" dark @click="VerBitacoraFecha()" style="margin-right:10px;">Generar</v-btn>
                      <v-btn  @click="dialogBitacorafecha=false" style="margin-right:10px;">Cancelar</v-btn>

                </v-flex>
    
          </v-card-text>
        
        </v-card>
         </v-dialog>





       


      </v-toolbar>
    </template>


   

    <template v-slot:[`item.responsable`]="{ item }">
       <span>{{ item.responsable }}</span>
    </template>




       <template v-slot:[`item.activo_salud`]="{ item }">
        <v-icon>
           {{ item.activo_salud==1 ? "done" : "label_off" }}
        </v-icon>
     </template>

  <template v-slot:[`item.contactos`]="{ item }">
          <v-icon @click="VerContactos(item)">contact_phone</v-icon>
    </template>

     <template v-slot:[`item.direcciones`]="{ item }">
          <v-icon @click="VerDirecciones(item)">apartment</v-icon>
    </template>


      <template v-slot:[`item.notx`]="{ item }">
          <v-icon @click="VerNoTX(item)">bus_alert</v-icon>
    </template>


     <!--template v-slot:[`item.gestiones`]="{ item }">
          <v-icon @click="VerGestiones(item)">connect_without_contact</v-icon>
    </template-->

      <template v-slot:[`item.bitacoras`]="{ item }">
        <v-icon @click="VerBitacoraCliente(item)">electric_rickshaw</v-icon>
    </template>

    
 


    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">
        Reset
      </v-btn>
    </template>


 

  </v-data-table>



</div>

  </v-flex>
</v-layout>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";




  export default {
    components:{
          
    },
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
       PaisesArray:[{text:'NI', value:'ni'},{text:'SV', value:'sv'},{text:'GT', value:'gt'},{text:'PA', value:'pa'}],
      headers: [
       // { text: 'Acciones', value: 'actions', sortable: false },
        { text: 'Nombre Cliente', value: 'nombre_cliente' },
        { text: 'Responsable', value: 'responsable' },  
        { text: 'Telefono', value: 'telefono' }, 
        { text: 'whatsapp', value: 'numero_whatsapp' },
       // { text: 'activo_salud', value: 'activo_salud' },
        { text: 'Contactos', value: 'contactos' },
        { text: 'Direcciones', value: 'direcciones' },
        { text: 'FlotaNoTx', value:'notx'},
        { text: 'Bitacoras', value:'bitacoras'},
        { text: 'Ejecutivo', value:'id_ejecutivo_cli.nombre_ejecutivo'},
        { text: 'Correo', value:'correo'}
      ],
     
      editedIndex: -1,
      editedItem: {
        nombre_cliente: '',
        usr_avl: '',
        pwd_avl:'',
        pais_avl: '',
        servidor_avl: '',
        responsable:'',
        telefono:'',
        correo:'',
        numero_whatsapp:'',
        dias_activo:'',
        id_ejecutivo_cli:'',
        activo:1,
        activo_salud:0,
        contactos:[],
        creado:new Date()
      },
      defaultItem: {
        nombre_cliente: '',
        usr_avl: '',
        pwd_avl:'',
        pais_avl: '',
        servidor_avl: '',
        responsable:'',
        telefono:'',
        correo:'',
        dias_activo:'',
        id_ejecutivo_cli:'',
        activo:1,
        contactos:[],
        activo_salud:0,
        creado:new Date()
      },

      diasArray:[{text:'lunes', value:1},{text:'martes', value:2},{text:'miercoles', value:3},
      {text:'jueves', value:4},{text:'viernes', value:5},{text:'sabado', value:6}],
      checkedDias:[],

      ArrayEjecutivos:[],
      ArrayClientes:[],
      arreglo:[],

       tableHeight: 0,

       switch1:true,
       LabelSwitch:'Activos',
       dialogContactos:false,


        headersContact:[
        { text: 'Nombre contacto', value: 'nombre_contacto' },
        { text: 'Telefonos', value: 'telefonos' },  
        { text: 'Correos', value: 'correos' }, 
        { text: 'Observacion', value: 'observacion' }  
        ],
        itemsContact:[],
        nombre_cliente_texto:'',
        dialogEdicionContacto:false,
        editedContacto:{},
        id_item_contacto:'',

        tipo_edicion:'nuevo',
        indice_contacto:0,

        dialogDeleteContacto:false,

        dialogDirecciones:false,


         dialogEdicionDirecciones:false,
         headersDirecciones:[
           
              { text: 'Tipo direccion', value: 'tipo' },
              { text: 'Texto', value: 'texto' },  
              { text: 'Latitud', value: 'latitud' }, 
              { text: 'Longitud', value: 'longitud' },
              { text: 'Mapa', value: 'mapa' },
              { text: 'Acciones', value: 'actions', sortable: false }
         ],
         itemsDirecciones:[],

         itemsTipoDireccion: ['Oficina', 'Casa', 'Plantel', 'Otro'],

         editedDireccion:{},

         tipo_edicion_direccion:'nuevo',
         indice_direccion:0,

      
       
          //datos de flota sin transmitir

          dialogNoTx:false,
          ArrayVehiculosCliente:[],
          ItemTX:{
            nombre_cliente:'',
            unidades_notx:0
          },

          id_cliente_ver_vehiculos:'',
          items_contacto:[], //selecionar a quien contactamos


           headersSelected:[
              { text: 'nombre', value: 'nombre' },
              { text: 'Placa', value: 'placa' },
              { text: 'Flota', value: 'flota' },

              { text: 'Fecha GPS', value: 'fecha_gps' },
              { text: 'Ubicacion', value: 'ubicacion' },

              { text: 'Estado', value: 'id_estado' },
              { text:'Fecha gestion', value:'fecha_gestion'},

              { text:'Contactado', value:'contactado'},
              { text:'Vence estado', value:'vence_estado'},
              { text:'Observacion', value:'observacion'},
              { text: 'Actualizado', value: 'actualizado' }
     
           ],
           itemSelected:[],


            ArrayEstados:[],
            editedItemTX:{
                  id_avl:'',
                  nombre:'',
                  placa:'',
                  flota:'',
                  icono:'',
                  fecha_gps:'',
                  fecha_tx:'',
                  evento:'',
                  velocidad:'',
                  direccion:'',
                  latitud:'',
                  longitud:'', 
                  id_estado:'',//aqui actualizar
                  fecha_gestion:new Date(),
                  contactado:'',
                  via_contacto:'',
                  vence_estado:'',
                  observacion:'',
                  id_veh_cliente:'',
                  comentario:'' 
            },
            editedIndexTX:0,
            dialogEditTX:false,

            menu1:false,
            menu2:false,
            menu3:false,
          date: new Date().toISOString().substr(0, 10),

          checkboxMultiplesVeh:false,
          checkboxAll:false,
          arrayMultipleRegistrar:[],

           arrayViaContacto: ['Llamada', 'Whatsapp', 'Correo', 'Telegram','Visita'],


           id_cliente_ver_gestiones:null,
           ItemGestion:{
                      nombre_cliente:'',
                      tipo_gestion:'',
                      departamento:'',
                      id_gestion_cliente:'',
                      descripcion:'',
                      actualizado:''
                    },

   
          dialogGestiones:false,

          headersGestiones:[
              { text: 'Tipo gestion', value: 'tipo_gestion' },
              { text: 'Departamento', value: 'departamento' },
              { text: 'Descripcion', value: 'descripcion' },
              { text: 'Actualizado', value: 'actualizado' },
          ],
          ArrayGestionesCliente:[],

          //Crear nueva unidad
          dialogNuevoVeh:false,
          searchtx:'',

          //detalle de vehiculos que si transmiten del AVL. 
          ArregloSiTXAVL:[],
          itemsiTX:[],

          id_avl_nuevo:'',


          //dialogo para procesar historial de gestiones por fecha
          dialogGestionfecha:false,
          menuFecha1:false,
          fecha_inicio:new Date().toISOString().substr(0, 10),
          menuFecha2:false,
          fecha_fin:new Date().toISOString().substr(0, 10),

          
          //dialogo de bitacoras registradas a este cliente
          dialogBitacoras:false,

           headersBitacoraCliente:[
              { text: 'Fecha', value: 'fecha' },
              { text: 'Hora Establecida', value: 'hora_establecida' },
              { text: 'Tipo Trabajo', value: 'id_tipo_trabajo' },
              { text: 'Estatus', value: 'status' },
              { text: 'Id Avl', value: 'id_avl' },
              { text: 'Placa', value: 'placa' },
              { text: 'Direccion', value: 'direccion' },
              { text: 'Observacion Operador', value: 'observacion_operador' }
           ],
            ArrayBitacoraCliente:[],

            ItemBitacora:{nombre_cliente:''},

            dialogDetalleBitacora:false,

            viewItem:{},
            ArrayTenicos:[],
            ArrayAccesorios:[],
            id_cliente_ver_bitacora:null,
            dialogBitacorafecha:false,

            searchBitacora:'',
            dialogDeleteDireccion:false,
            editedIndexDireccion:false,
            id_item_direcciones:'',
        


      
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo cliente' : 'Edicion cliente'
      },

        ...mapState(['usuario', 'token']),

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
     



    dialogEdicionContacto(val){
        if(val==false){
           this.tipo_edicion='nuevo';
           this.editedContacto={};
        }
    },

    dialogEditTX(val){
       if(val==false){
         this.editedItemTX={};
         this.editedIndexTX=0;
       }
    },

    dialogNuevoVeh(val){
       if(val==false){
         this.editedItemTX={};
         this.editedIndexTX=0;
       }
    },
          

      switch1(val){
          if(val==true){
             this.LabelSwitch='Activos';
             this.ActulizarTabla();
          }else{
               this.LabelSwitch='Inactivos';
                this.ActulizarTabla();
          }
      }


    },

    created () {

      this.$root.$refs.M.detenerTimer();

      this.listar();
      this.listarEjecutivos();
      this.listarEstados();
      this.listarTecnicos();

    },

    methods: {

      ...mapMutations(['mostrarLoading','ocultarLoading']),
    
      listar(){

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

           me.arreglo=[];
          axios.get('clientes-all',
         config
          ).then(function(response){
             console.log(response);
             me.ArrayClientes=[];
            me.arreglo=response.data;

            me.arreglo.map(function(x){

              //aqui solo ponemos los datos si es admin o si son del usuario ejecutvo actual
             
               
                 if((me.switch1==true)&&(x.activo==1)){
                      me.ArrayClientes.push(x);
                 }
                   
               
                 
            });



           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },


      listarEjecutivos(){
        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'Accediendo a datos'});
          let arregloe=[];
         let me=this;
          axios.get('ejecutivos',
         config
          ).then(function(response){
             console.log(response);
             arregloe=response.data;

             arregloe.map(function(x){
              if(x.rol=='ATC'){
                   me.ArrayEjecutivos.push({text:x.nombre_ejecutivo, value:x._id});
              }
            
             });

           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });
      },


      listarEstados(){

          let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('estados',
          config
          ).then(function(response){
             console.log(response);
           
             let arreglo=response.data;

             arreglo.map(function(x){
               me.ArrayEstados.push({text:x.nombre_estado, value:x._id});
             });

            me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

  
      renderizadDias(valor){
        
        let texto='';
        this.diasArray.map(function(x){
            if(x.value==valor){
              texto=x.text;
            }
        });

        return(texto);

      },

      onResize() {

                if (window.innerWidth < 769){

                  //mobile
                  this.tableHeight = window.innerHeight - 20;
                }else{
                  //pantalla grande
                  this.tableHeight = window.innerHeight - 245;
                }
       },


       reloadMap(){
          setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 250);
       },


      onMapClick(e) {
        this.editedDireccion.latitud = e.latlng.lat;
        this.editedDireccion.longitud = e.latlng.lng;

        this.point={lat: e.latlng.lat, lng: e.latlng.lng};
      },

       ActulizarTabla(){

         console.log('estado de swithc:'+this.switch1);

         let me=this;
          me.ArrayClientes=[];


             me.arreglo.map(function(x){

              //aqui solo ponemos los datos si es admin o si son del usuario ejecutvo actual
             
            

                 if((me.switch1==true)&&(x.activo==1)){
                      me.ArrayClientes.push(x);
                 }

                  if((me.switch1==false)&&(x.activo==0)){
                      me.ArrayClientes.push(x);
                      console.log('aqio bien');
                 }

            
                    console.log('varlo activo:'+x.activo);
                 
            });


       },

       verInfo(){
         alert("Observaciones:\n correos deben separarse por punto y comas, \n servidores pueden establecerse por pais: ni, sv, pa, gt o bien empresa ejemplo: avldiana");
       },

       VerContactos(item){
         this.indice_contacto=0;
         this.editedContacto={};
         this.nombre_cliente_texto=item.nombre_cliente;
         this.id_item_contacto=item._id;
         this.itemsContact=item.contactos;
         this.dialogContactos=true;
         this.tipo_edicion='nuevo';

       },

      VerDirecciones(item){

      

         this.indice_direccion=0;
         this.editedDireccion={};
         this.nombre_cliente_texto=item.nombre_cliente;
         this.itemsDirecciones=item.direcciones;
         this.dialogDirecciones=true;
         this.id_item_direcciones=item._id; //id del cliente a actualizar

         this.reloadMap();
         


        console.log(item);
      },

       deleteDireccion (item) {
        this.editedIndexDireccion = this.itemsDirecciones.indexOf(item);
        this.dialogDeleteDireccion = true;
      },

        deleteItemConfirmDireccion () {

          this.itemsDirecciones.splice(this.editedIndexDireccion, 1);

            let config={headers:{token:this.token}};
            
                    axios.put(`clientes/${this.id_item_direcciones}`,
                              { 'direcciones':this.itemsDirecciones},
                            config
                        ).then(response=>{
                      
                        console.log(response);
                     

                        this.dialogDeleteDireccion=false;
                        this.listar();

                  }).catch(e=>{
                        console.log(e)
                  }); 

      },

  
     openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
        },

     getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
      },

      getDateVence(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day;
    return formatedDay + "-" + formatedMonth + "-" + year;
      },

      VerNoTX(item){

         let me=this;

        this.items_contacto=[];


         item.contactos.map(function(z){
                  me.items_contacto.push(z.nombre_contacto+'-'+z.telefonos);
          });


        this.ItemTX.nombre_cliente=item.nombre_cliente;
        this.id_cliente_ver_vehiculos=item._id;
           
        

                    //pedimos unidades sin tx del cliente especifico

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'Accediendo a datos'});

                    this.ArrayVehiculosCliente=[];


                     
                      axios.get(`vehiculos/${item._id}`, config
                                  ).then(function(response){
                                    console.log(response);

                                      me.ArrayVehiculosCliente=response.data;

                                      me.ItemTX.unidades_notx=me.ArrayVehiculosCliente.length;
                                      me.itemSelected=response.data;
                                      me.ocultarLoading();
                                      me.dialogNoTx=true;

                                     
                        
                                    }).catch(function(error){
                                  console.log(error);
                                });



      },//fin de ver unidades sin TX.

  
      recargarVehiculosCliente(){


       this.editedItemTX.id_estado=''; 
       this.editedItemTX.contactado='';
       this.editedItemTX.observacion='';

        //pedimos unidades sin tx del cliente especifico

                      let config={headers:{token:this.token}};
                      this.mostrarLoading({titulo:'Accediendo a datos'});

                      this.ArrayVehiculosCliente=[];


                        let me=this;
                        axios.get(`vehiculos/${this.id_cliente_ver_vehiculos}`, config
                                    ).then(function(response){
                                      console.log(response);

                                        me.ArrayVehiculosCliente=response.data;

                                        me.ItemTX.unidades_notx=me.ArrayVehiculosCliente.length;
                                        me.itemSelected=response.data;
                                        me.ocultarLoading();
                                        me.dialogNoTx=true;
                          
                                      }).catch(function(error){
                                    console.log(error);
                                  });
      },

      CambiarTablaEdicion(){

        if(this.checkboxMultiplesVeh==true){

           this.headersSelected=[
              {text: 'Seleccionar', value:'estado_check'},
              { text: 'nombre', value: 'nombre' },
              { text: 'Placa', value: 'placa' },
              { text: 'Flota', value: 'flota' },

              { text: 'Fecha GPS', value: 'fecha_gps' },
              { text: 'Ubicacion', value: 'ubicacion' },

              { text: 'Estado', value: 'id_estado' },
              { text:'Fecha gestion', value:'fecha_gestion'},

              { text:'Contactado', value:'contactado'},
              { text:'Vence estado', value:'vence_estado'},
              { text:'Observacion', value:'observacion'},
              { text: 'Actualizado', value: 'actualizado' },
     
     
           ];

        }else{

          this.headersSelected=[
            
              { text: 'nombre', value: 'nombre' },
              { text: 'Placa', value: 'placa' },
              { text: 'Flota', value: 'flota' },

              { text: 'Fecha GPS', value: 'fecha_gps' },
              { text: 'Ubicacion', value: 'ubicacion' },

              { text: 'Estado', value: 'id_estado' },
              { text:'Fecha gestion', value:'fecha_gestion'},

              { text:'Contactado', value:'contactado'},
              { text:'Vence estado', value:'vence_estado'},
              { text:'Observacion', value:'observacion'},
              { text: 'Actualizado', value: 'actualizado' },
     
     
           ];

        }

      },

   
      VerGestiones(item){

          let me=this;

        this.items_contacto=[];


         item.contactos.map(function(z){
                  me.items_contacto.push(z.nombre_contacto+'-'+z.telefonos);
          });


        this.ItemGestion.nombre_cliente=item.nombre_cliente;
        this.id_cliente_ver_gestiones=item._id;
           
        

                    //pedimos unidades sin tx del cliente especifico

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'Accediendo a datos'});

                    this.ArrayGestionesCliente=[];


                     
                       axios.post('gestiones-ultimas', 
                        {
                          'id_gestion_cliente':item._id,
                          'departamento':'Atc'  
                        },config
                        ).then(function(response){
                                    console.log(response);

                                      me.ArrayGestionesCliente=response.data;

                                   
                                      me.ocultarLoading();
                                      me.dialogGestiones=true;
                        
                                    }).catch(function(error){
                                  console.log(error);
                                });



      },

       VerGestionesFecha(){

          let me=this;

          //pedimos unidades sin tx del cliente especifico

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          this.ArrayGestionesCliente=[];
                     
            axios.post('gestiones-cliente', 

                        {
                          'id_gestion_cliente':this.id_cliente_ver_gestiones,
                          'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                          'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10),
                          'departamento':'Atc' 
                        }
                        ,config
                        ).then(function(response){
                          console.log(response);

                            me.ArrayGestionesCliente=response.data;

                            me.dialogGestionfecha=false;

                          
                            me.ocultarLoading();
                            me.dialogGestiones=true;
              
                          }).catch(function(error){
                        console.log(error);
                      });



      },

     evaluarVencimineto(fechaVence){

            let icon='';
            let fecha_hoy=new Date();
            fecha_hoy.setHours(0,0,0,0);


            let arra_fecha=fechaVence.split("T");
                          fechaVence=arra_fecha[0];
         
            let fecha_recibida=new Date(fechaVence +" 00:00:00");
             fecha_recibida.setHours(0,0,0,0);
          
            console.log(fecha_hoy+', con:'+fecha_recibida);

            console.log(fecha_hoy.getTime()+', con:'+ fecha_recibida.getTime());
            
              if(fecha_hoy.getTime() === fecha_recibida.getTime()){
                     icon='error';

                 }else if(fecha_hoy < fecha_recibida){
                          icon='';
                        
                 }else if(fecha_hoy > fecha_recibida){
                         icon='error';   
                 }   

    
        return(icon);


      },



  

     ValidariconoSiTX(fecha_gps){

        let estado_tx=false;

            let fecha_hoy=new Date(this.CalcularFechaHoy()+' 01:00:00');
            let fecha_recibida=new Date(fecha_gps);


              if((fecha_hoy.getDate()==fecha_recibida.getDate())
                 &&(fecha_hoy.getMonth()==fecha_recibida.getMonth())
                 &&(fecha_hoy.getFullYear()==fecha_recibida.getFullYear())) {
                    
                    estado_tx=true;
                
                 }else{
                     estado_tx= false;   
                    
                 }


                 return(estado_tx);

      },

     CalcularFechaHoy(){
	
        var fecha=new Date();
        var mes=fecha.getMonth()+1;
        if(mes<10)mes='0'+mes;
        var dia=fecha.getDate();
        if(dia<10)dia='0'+dia;
        var textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

        return(textofecha);
      },



      SyncronizarAVLVeh(){

          this.ArregloSiTXAVL=[];
          this.itemsiTX=[];
          this.id_avl_nuevo='';

            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'Accediendo a datos'});
            let me=this;

            //pedir al backend que actualize los datos de no tx de este cliente

                axios.get(`vehiculos-sync/${this.id_cliente_ver_vehiculos}`,
                config
                ).then(response=>{
                    console.log(response);
                    let respuesta=response.data;

                        respuesta.map(function(x){

                           let existe_detalle_notx=false;

                            me.ArrayVehiculosCliente.map(function(y){
                                if(y.id_Avl==x.ID){
                                  existe_detalle_notx=true;
                                }
                            });

                            if(existe_detalle_notx==false){
                              me.ArregloSiTXAVL.push(x);
                              me.itemsiTX.push({text:x.Name+' : '+x.Plate+'. '+ x.Date, value:x.ID})
                            }

                          


                        });
                      
                 


                   me.recargarVehiculosCliente();
                   me.ocultarLoading();

                   //solo para nuevo camion
                 

                }).catch(e=>{
                      console.log(e)
                    
                });

      },


        //Historial bitacora por cliente
       //Historial bitacora por cliente
       
      VerBitacoraCliente(item){

           this.ItemBitacora={'nombre_cliente':item.nombre_cliente};

           this.id_cliente_ver_bitacora=item._id;

     
             let me=this;

          //pedimos unidades sin tx del cliente especifico

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

        
                      axios.get(`bitacoras-cliente/${item._id}`, config
                                  ).then(function(response){
                                   // console.log(response);

                                      me.ArrayBitacoraCliente=response.data;

                                      me.ocultarLoading();
                                      me.dialogBitacoras=true;
                        
                                    }).catch(function(error){
                                  console.log(error);
                                });
      },

      VerDetalleBitacora(item){

        this.dialogDetalleBitacora=true;
        this.viewItem=item;

        console.log(item);


      },

      ConvertirFecha(fecha_parametro){

        let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();

        return(texto);

      },

      renderizarTecnico(_id){
          let texto='';

           this.ArrayTenicos.map(function(x){
                if(x._id==_id){ texto=x.nombre_tecnico;}
           });

           return(texto);

      },

     listarTecnicos(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;

        this.ArrayTenicos=[];
        this.items_tecnicos=[];  

          axios.get('tecnicos', config
                    ).then(function(response){
                      console.log(response);
                      me.ArrayTenicos=response.data;

                         me.ArrayTenicos.map(function(x){
                                        me.items_tecnicos.push({'text':x.nombre_tecnico, 'value':x._id});
                            });

                          me.listarAccesorios();

                         me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });




      },

      renderizarAccesorio(_id){

        let texto='';

           this.ArrayAccesorios.map(function(x){
                if(x._id==_id){ texto=x.nombre_accesorio;}
           });

           return(texto);


      },

      listarAccesorios(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;


         this.ArrayAccesorios=[];
    

         this.data_excel=[];

             axios.get('accesorios', config
                    ).then(function(response){
                      console.log(response);
                      me.ArrayAccesorios=response.data;

            
                         me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });


      },

      VerBitacoraFecha(){


        
          let me=this;

          //pedimos unidades sin tx del cliente especifico

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          this.ArrayBitacoraCliente=[];
                     
            axios.post('bitacorasfecha-cliente', 

                        {
                          'id_bitacora_cliente':this.id_cliente_ver_bitacora,
                          'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                          'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10) 
                        }
                        ,config
                        ).then(function(response){
                          console.log(response);

                            me.ArrayBitacoraCliente=response.data;

  

                          
                            me.ocultarLoading();
                            me.dialogBitacoras=true;
                            me.dialogBitacorafecha=false;
              
                          }).catch(function(error){
                        console.log(error);
                      });

      },

       DescargarPdf(title){
         

         console.log('vamos a descargar:'+title);

           let me =this;

              axios.post('downloadpdf', {fileName: title},
                          {responseType:'blob'},
          
                    ).then(function(response){

                      
                        var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'applications/vnd.pdf'}));
                        var fileLink = document.createElement('a');
                      
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', title);
                        document.body.appendChild(fileLink);
                      
                        fileLink.click();


                        me.ocultarLoading();
                        
                    }).catch(function(error){
                        console.log(error);
                    });

       },


     save () {
        if (this.editedIndex > -1) {


       



           //actualizar datos de item
                    let config={headers:{token:this.token}};
                    let me=this;
             
                    let id=this.editedItem._id;
                     this.mostrarLoading({titulo:'Accediendo a datos'});

                    axios.put(`clientes/${id}`,
                      { 
                           'nombre_cliente':this.editedItem.nombre_cliente,
                            'usr_avl':this.editedItem.usr_avl,
                            'pwd_avl':this.editedItem.pwd_avl,
                            'pais_avl':this.editedItem.pais_avl,
                            'servidor_avl':this.editedItem.servidor_avl,
                            'telefono':this.editedItem.telefono,
                            'numero_whatsapp':this.editedItem.numero_whatsapp,
                            'responsable':this.editedItem.responsable,
                            'correo':this.editedItem.correo,
                            'dias_activo':this.checkedDias,
                            'id_ejecutivo_cli':this.editedItem.id_ejecutivo_cli,
                            'cantidad_vehiculos':0,
                            'activo':this.editedItem.activo,
                            'activo_salud':this.editedItem.activo_salud
                       },
                      config

                        ).then(response=>{
                      
                        console.log(response);
                         me.ocultarLoading();
                     
                        this.listar();
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });


         


        } else {
         
          //guardamos datos

           //nuevo cliente

                let me=this;
                let insertar=false;
                //insertar cliente sin campos de macro salud solo cliente
                if((this.editedItem.nombre_cliente!='')&&(this.editedItem.pais_avl!='')&&
                    (this.editedItem.servidor_avl!='')&&(this.editedItem.servidor_avl!='')
                    &&(this.editedItem.activo_salud==0)&&(this.editedItem.id_ejecutivo_cli!='') ){
                          insertar=true;
                     }


          if(((this.editedItem.nombre_cliente!='')&&(this.editedItem.usr_avl!='')&&
          (this.editedItem.pwd_avl!='')&&(this.editedItem.pais_avl!='')&&(this.editedItem.servidor_avl!='')&&
          (this.editedItem.id_ejecutivo_cli!='') &&(this.editedItem.responsable!=''))|| (insertar==true)){ 


                  let unico=true;

                  me.ArrayClientes.map(function(x){
                        if((x.nombre_cliente===me.editedItem.nombre_cliente)||(x.usr_avl===me.editedItem.usr_avl)){
                                if((me.editedItem.usr_avl=='')&&(x.nombre_cliente!=me.editedItem.nombre_cliente)){ 
                                      unico=true;
                                  }else{
                                      unico=false;
                                  }
                                 
                        }
                  });

                           


               if(unico==true){


                            let config={headers:{token:this.token}};
                            this.mostrarLoading({titulo:'Accediendo a datos'});

                              axios.post('nuevo-cliente',
                                        { 
                                          'nombre_cliente':this.editedItem.nombre_cliente,
                                          'usr_avl':this.editedItem.usr_avl,
                                          'pwd_avl':this.editedItem.pwd_avl,
                                          'pais_avl':this.editedItem.pais_avl,
                                          'servidor_avl':this.editedItem.servidor_avl,
                                          'telefono':this.editedItem.telefono,
                                          'numero_whatsapp':this.editedItem.numero_whatsapp,
                                          'responsable':this.editedItem.responsable,
                                          'correo':this.editedItem.correo,
                                          'dias_activo':this.checkedDias,
                                          'id_ejecutivo_cli':this.editedItem.id_ejecutivo_cli,
                                          'cantidad_vehiculos':0,
                                          'activo':this.editedItem.activo,
                                          'activo_salud':this.editedItem.activo_salud,
                                          'contactos_conta':[],
                                          'direcciones_conta':[]

                                          },
                                    config
                              ).then(response=>{
                              
                                this.close();
                                me.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                                  
                              });


                 }else{
                 alert('cliente repetido');
               }




        }else{
          alert('faltan campos');
        }








        }
        
      },
       



      




    },
  }
</script>