<template>
  <v-card>
 
        <v-tabs v-model="tabs" fixed-tabs>

          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#mobile-tabs-5-0" class="primary--text">
            <v-icon>account_circle</v-icon>
             <span class="mx-1">Clientes Generales</span>
          </v-tab>

           <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>contact_mail</v-icon>
            <span class="mx-1">Salud Flota</span>
          </v-tab>

        </v-tabs>

    <v-tabs-items v-model="tabs">

        <v-tab-item :value="'mobile-tabs-5-0'">
        <v-card flat>
          <v-card-text>
        <Clientes/>
          </v-card-text>
        </v-card>
      </v-tab-item>


       <v-tab-item :value="'mobile-tabs-5-1'">
        <v-card flat>
          <v-card-text>
           <ClientesSalud/>
          </v-card-text>
        </v-card>
      </v-tab-item>



     

      
    </v-tabs-items>

  </v-card>
</template>

<script>


import ClientesSalud from './ClientesSalud.vue';

import Clientes from './Clientes.vue';

  export default {
      components:{
         Clientes,ClientesSalud
         },
    data () {
      return {
        dialogExit:false,
        tabs: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
     methods:{

    }
  }
</script>